<div
  class="panel-actions tw-flex tw-justify-center tw-font-normal tw-mt-auto"
  [class.panel-opened]="isParticipationPanelOpened()"
  [class.mobile-view]="isMobile()"
>
  @if (isParticipationPanelOpened() && !isMobile()) {
    <div class="tw-flex tw-flex-col tw-items-center tw-gap-1.5 tw-w-1/3">
      <div class="gray-400 tw-text-sm/4">{{ 'TRADE_PANEL.BET_SUM' | translate }}</div>
      <div class="panel-actions__label tw-inline-flex tw-justify-center tw-text-2xl">
        <app-currency
          class="panel-actions__label tw-inline-flex tw-justify-center tw-text-2xl"
          [value]="participatedItemsSum() || 0"
          [delimiterRest]="'comma'"
        />
        @if (selectedItemsSum(); as selectedItemsSum) {
          <app-currency
            class="panel-actions__label tw-inline-flex tw-justify-center tw-text-2xl tw-pl-1 tw-font-bold add-to-highlight"
            [value]="selectedItemsSum"
            [hidePrefix]="true"
            [delimiterRest]="'comma'"
          />
        }
      </div>
    </div>
  }
  @if (isParticipationPanelOpened() && isMobile()) {
    <div
      class="clear-selection-btn tw-flex tw-items-center tw-justify-center"
      (click)="clearSelectedItems.emit()"
    >
      <mat-icon svgIcon="close" />
    </div>
  }
  <div class="panel-actions__action-btn tw-flex tw-flex-col tw-gap-2 tw-p-2 tw-w-fit">
    <button
      class="btn tw-min-w-80 tw-w-80 tw-inline-flex !tw-rounded-2xl"
      [class]="btnCurrentClass()"
      (click)="clickOnButton()"
    >
      @if (selectedItemsLength() || participatedItemsLength()) {
        @if (selectedItemsLength() && isParticipationPanelOpened()) {
          {{ 'TRADE_PANEL.BET' | translate }}:
          <app-currency [value]="selectedItemsSum() || 0" />
        }
        @if (!selectedItemsLength() && participatedItemsLength()) {
          {{ 'TRADE_PANEL.YOUR_BET' | translate }}:
          <app-currency [value]="participatedItemsSum() || 0" />
        }
      } @else {
        {{ (isParticipationPanelOpened() ? 'GAMES.CLASSIC_GAME.CHOOSE_ITEM' : 'GAMES.CLASSIC_GAME.MAKE_BET') | translate }}
      }
    </button>
    <div class="panel-actions__action-btn-tooltip gray-400 tw-flex tw-justify-between tw-items-center tw-font-normal tw-text-xs">
      @if (gameSettings()?.minBet; as minBet) {
        <div
          class="bet-condition tw-inline-flex tw-gap-1"
          [@bellAnimation]="isBetInvalid()"
        >
          {{ 'TRADE_PANEL.BET_TOTAL' | translate }}
          <app-currency [value]="minBet" />
          @if (gameSettings()?.maxBet; as maxBet) {
            -
            <app-currency [value]="maxBet" />
          }
        </div>
      }
      @if (gameSettings()?.maxItemsPerTrade; as maxItemsPerTrade) {
        <div
          class="items-condition"
          [@bellAnimation]="isBetItemsInvalid()"
        >
          {{ 'TRADE_PANEL.BET_ITEMS' | translate }}
          {{ gameSettings()?.minItemsPerTrade || 1 }} - {{ maxItemsPerTrade }}
        </div>
      }
    </div>
  </div>
  @if (isParticipationPanelOpened() && !isMobile()) {
    <div class="tw-flex tw-flex-col tw-items-center tw-gap-2 tw-w-1/3">
      <div class="gray-400 tw-text-sm">{{ 'TRADE_PANEL.BET_ITEMS' | translate }}</div>
      <div class="panel-actions__label tw-flex tw-items-center tw-justify-center tw-text-2xl tw-font-bold">
        {{ participatedItemsLength() || newItemsLength() }}
        @if (participatedItemsLength() && newItemsLength()) {
          <span class="add-to-highlight">{{ newItemsLength() }}</span>
        }
        @if (gameSettings()?.maxItemsPerTrade; as maxItemsPerTrade) {
          /{{ maxItemsPerTrade }}
        }
        @if (newItemsLength()) {
          <div
            class="icon-wrapper tw-flex tw-pl-2 tw-cursor-pointer"
            (click)="clearSelectedItems.emit()"
          >
            <mat-icon svgIcon="close-circle" />
          </div>
        }
      </div>
    </div>
  }
</div>
