import { ChangeDetectionStrategy, Component, computed, input, model, output } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { IGameSettings } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';

import { CurrencyComponent } from '@app/core/currency';
import { bellAnimation } from '@app/ui/animations';

@Component({
  standalone: true,
  selector: 'app-participation-panel-actions',
  templateUrl: './participation-panel-actions.component.html',
  styleUrls: ['./participation-panel-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, CurrencyComponent, MatIcon],
  animations: [bellAnimation],
})
export class ParticipationPanelActionsComponent {
  readonly isParticipationPanelOpened = input(false);
  readonly actionBtnIsDisabled = input(true);
  readonly isMobile = input(false);
  readonly isBetInvalid = model(false);
  readonly isBetItemsInvalid = model(false);

  readonly participatedItemsSum = input(0);
  readonly participatedItemsLength = input(0);
  readonly selectedItemsSum = input(0);
  readonly newItemsLength = input(0);
  readonly selectedItemsLength = input(0);

  readonly gameSettings = input<IGameSettings | null>(null);

  readonly panelBtnAction = output();
  readonly clearSelectedItems = output();

  readonly btnCurrentClass = computed(() => {
    const isParticipationPanelOpened = this.isParticipationPanelOpened();
    const participatedItemsLength = this.participatedItemsLength();
    const newItemsLength = this.newItemsLength();
    const actionBtnIsDisabled = this.actionBtnIsDisabled();

    if (actionBtnIsDisabled || (isParticipationPanelOpened && !newItemsLength && !participatedItemsLength)) {
      return 'btn-basic--design';
    }

    if (newItemsLength || (!isParticipationPanelOpened && !participatedItemsLength)) {
      return 'btn-primary--design';
    }

    if (participatedItemsLength && !newItemsLength) {
      return 'btn-default--design';
    }

    return '';
  });

  clickOnButton(): void {
    if (!this.actionBtnIsDisabled()) {
      this.panelBtnAction.emit();
      return;
    }
    if (this.isBetInvalid()) {
      this.isBetInvalid.update((value) => false);
      setTimeout(() => this.isBetInvalid.update((value) => true), 100);
    }
    if (this.isBetItemsInvalid()) {
      this.isBetItemsInvalid.update((value) => false);
      setTimeout(() => this.isBetItemsInvalid.update((value) => true), 100);
    }
  }
}
