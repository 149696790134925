import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIcon } from '@angular/material/icon';
import { ICurrencyRate } from '@dev-fast/types';

import { CurrencyService } from '../../currency.service';
import { getRateKey } from '../utils';

@Component({
  selector: 'app-currency-text',
  standalone: true,
  templateUrl: './currency-text.component.html',
  styleUrls: ['./currency-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIcon],
})
export class CurrencyTextComponent {
  readonly #currencyService: CurrencyService = inject(CurrencyService);

  iconClass = input<string>();
  customIcon = input<string>();
  type = input<'primary' | 'secondary'>('primary');
  text = input<string>();
  cKey = input<string>();
  hidePrefix = input<boolean>();

  #currencyList = toSignal<ICurrencyRate[] | null | undefined>(this.#currencyService.currencyList$);
  currency = toSignal<ICurrencyRate | null | undefined>(this.#currencyService.currency$);
  #prefix = computed<string>(() => {
    const cKey = this.cKey();
    const currencyList = this.#currencyList();

    if (cKey) {
      return this.#getPrefix(cKey);
    }

    if (currencyList) {
      const rateKey = getRateKey(this.currency(), this.cKey());
      return this.#getPrefix(rateKey);
    }

    return '◎';
  });
  transformText = computed<string[]>(() => {
    const currency = this.currency();
    const text = this.text();
    const prefix = this.#prefix();

    if (currency === null || !text) {
      return [''];
    }
    const hasPrefix = text.includes(prefix);
    if (!hasPrefix) {
      return [text];
    }
    return text.split(prefix);
  });
  icon = computed<string>(() => {
    return this.customIcon() || `coin-${this.type()}`;
  });

  #getPrefix(key: string): string {
    const currency = this.currency();

    const currencyBycKey = this.#getCurrencyBycKey(key);
    const prefix = currencyBycKey?.prefix.trim() || (!this.hidePrefix() && currency?.prefix) || '';
    return prefix || '◎';
  }

  #getCurrencyBycKey = (cKey: string | undefined): ICurrencyRate | undefined => {
    const currencyList = this.#currencyList();
    return currencyList?.find((currency) => currency.key === cKey);
  };
}
