import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Subscription } from 'rxjs';

import { ClickOnItemForTrade } from './participation-panel.actions';
import { PARTICIPATION_PANEL_STATE, ParticipationPanelStateModel } from './participation-panel.model';

@State<ParticipationPanelStateModel>({
  name: 'participation_panel',
  defaults: PARTICIPATION_PANEL_STATE,
})
@Injectable()
export class ParticipationPanelState {
  private subscriptions: Subscription;

  @Selector()
  static gamesConfig({ gamesConfig }: ParticipationPanelStateModel): any[] {
    return gamesConfig;
  }

  constructor(private readonly store: Store) {
    this.subscriptions = new Subscription();
  }

  @Action(ClickOnItemForTrade)
  clickOnInventoryItem({ dispatch }: StateContext<ParticipationPanelStateModel>, { id }: ClickOnItemForTrade): void {
    // const { selectedItems, items } = this.store.selectSnapshot(InventoryState);
    // const { currentGameSettings } = this.store.selectSnapshot(GamesState);
    // const activeItems = []; // предметы уже в ставке
    // if (items) {
    //   const item = items.find((el: IInventoryItem) => el.id === id);
    //   const selected = selectedItems.some((el: IInventoryItem) => el.id === id);
    //   if (selected) {
    //     dispatch(new ClickOnInventoryItem(id));
    //   } else if (item) {
    //     const activeLength = activeItems?.length || 0;
    //     const selectedLength = selectedItems?.length || 0;
    //     if ((currentGameSettings?.maxItemsPerTrade || 0) - activeLength - selectedLength) dispatch(new ClickOnInventoryItem(id));
    //   }
    // }
  }
}
