<div
  class="participation-panel-filter-controls tw-flex tw-justify-between tw-items-center tw-font-normal tw-text-nowrap"
  [class.mobile-view]="isMobile"
>
  <div
    class="by-price__sorting-method tw-flex tw-items-center tw-gap-2 tw-cursor-pointer tw-pl-0"
    [class.sort-by-desc]="sortBy === InventorySortingTypes.MIN_PRICE"
    (click)="changeSortingDirection()"
  >
    @if (isMobile) {
      <app-currency-icon />
      <mat-icon [svgIcon]="sortBy === InventorySortingTypes.MAX_PRICE ? 'sort-3-line' : 'sort-3-line-asc'" />
    } @else {
      <div>{{ 'LOCAL.INVENTORY.SORT_PRICE' | translate }}</div>
      <mat-icon
        class="tw-transition-transform tw-duration-300"
        svgIcon="arrow-down-compact-fill"
      />
    }
  </div>
  @if (isMobile) {
    <div
      class="highlight-btn tw-rounded-lg tw-ml-4 tw-mr-auto tw-cursor-pointer"
      [matMenuTriggerFor]="sortMenu"
    >
      <app-currency-text [text]="'◎ ' + minPrice + (maxPrice ? ' - ' + '◎ ' + maxPrice : '+')" />
    </div>
    <mat-menu
      class="participation-panel-filter-controls participation-panel-filter__dropdown tw-rounded-lg"
      #sortMenu="matMenu"
      xPosition="after"
    >
      @for (option of filterOptions; track option) {
        <div
          class="control__btn tw-rounded-lg tw-cursor-pointer"
          (click)="filterByPriceRange(option)"
        >
          <app-currency-text [text]="'◎ ' + option.from + (option.to ? ' - ' + '◎ ' + option.to : '+')" />
        </div>
      }
    </mat-menu>
  } @else {
    <div class="tw-flex tw-gap-px">
      @for (option of filterOptions; track option) {
        <div
          class="control__btn tw-rounded-lg tw-cursor-pointer"
          [class.highlight-btn]="option.from === minPrice && option.to === maxPrice"
          (click)="filterByPriceRange(option)"
        >
          <app-currency-text [text]="'◎ ' + option.from + (option.to ? ' - ' + '◎ ' + option.to : '+')" />
        </div>
      }
    </div>
  }
  <button
    (click)="onNavigateToInventory()"
    class="to-inventory highlight-btn tw-rounded-lg tw-cursor-pointer tw-px-6 tw-py-2"
  >
    {{ 'LOCAL.CLASSIC_GAME.BET_PANEL.INVENTORY' | translate }}
  </button>
</div>
