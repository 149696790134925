import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CardStatusEnum, IGameSettings, IInventoryItem, TradeOriginalItem } from '@dev-fast/types';

@Component({
  selector: 'app-participation-panel-items-mobile',
  templateUrl: './participation-panel-items-mobile.component.html',
  styleUrls: ['./participation-panel-items-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipationPanelItemsMobileComponent {
  @Input() participatedItems: TradeOriginalItem[] | null = null;
  @Input() participatedItemsSum: number | null = 0;
  @Input() selectedItems: IInventoryItem[] | null = null;
  @Input() inventoryItems: IInventoryItem[] | null = null;
  @Input() selectedItemsSum: number | null = 0;
  @Input() selectedItemsCount: number | null = 0;
  @Input() timestamp: number | null = 0;
  @Input() gameSettings: IGameSettings | null = null;
  @Output() unselectItem: EventEmitter<IInventoryItem> = new EventEmitter<IInventoryItem>(false);

  get itemsArrayMock(): any[] {
    const activeLength = this.participatedItems?.length || 0;
    const selectedLength = this.selectedItemsCount || 0;
    const arrayLength = (this.gameSettings?.maxItemsPerTrade || 0) - activeLength - selectedLength;
    return new Array(arrayLength > 0 ? arrayLength : 0);
  }

  highlightMockItem(index: number): boolean {
    const activeLength = this.participatedItems?.length || 0;
    const minItemsPerTrade = this.gameSettings?.minItemsPerTrade || 1;
    const selectedLength = this.selectedItemsCount || 0;
    return index < minItemsPerTrade - selectedLength - activeLength;
  }

  onInventoryItem(selectedITem: IInventoryItem): void {
    if (selectedITem.participateStatus !== CardStatusEnum.SELECTED) {
      return;
    }
    this.unselectItem.emit(selectedITem);
  }
}
