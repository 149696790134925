import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, TypeProvider } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { ItemSimpleCardComponent, MobileWrapperComponent, TradeSkinComponent } from '@dev-fast/ui-components';
import { TranslateModule } from '@ngx-translate/core';
import { InViewportModule } from 'ng-in-viewport';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgScrollbarReachedModule } from 'ngx-scrollbar/reached-event';

import { CurrencyComponent } from '@app/core/currency';
import { CurrencyIconComponent } from '@app/core/currency/components/currency-icon/currency-icon.component';
import { CurrencyTextComponent } from '@app/core/currency/components/currency-text/currency-text.component';
import { LangRouterModule } from '@app/shared/directives';
import { CustomAsyncPipeModule } from '@app/shared/pipes';

import { ParticipationPanelFilterComponent, ParticipationPanelItemsMobileComponent } from './participation-panel/components';
import { ParticipationPanelActionsComponent } from './participation-panel/components/participation-panel-actions/participation-panel-actions.component';
import { ParticipationPanelComponent } from './participation-panel/participation-panel.component';
import { PARTICIPATION_PANEL_ENGINE } from './symbols';

@NgModule({
  declarations: [ParticipationPanelComponent, ParticipationPanelFilterComponent, ParticipationPanelItemsMobileComponent],
  exports: [ParticipationPanelComponent],
  imports: [
    CommonModule,
    RouterModule,
    InViewportModule,
    NgScrollbarModule,
    //MatModules
    MatIconModule,
    CurrencyComponent,
    MatMenuModule,
    //Pipes
    TranslateModule,
    // Components
    TradeSkinComponent,
    MobileWrapperComponent,
    CustomAsyncPipeModule,
    LangRouterModule,
    NgScrollbarReachedModule,
    ItemSimpleCardComponent,
    CurrencyTextComponent,
    CurrencyIconComponent,
    ParticipationPanelActionsComponent,
  ],
})
export class ParticipationPanelModule {
  static forRoot(engine: TypeProvider): ModuleWithProviders<ParticipationPanelModule> {
    return {
      ngModule: ParticipationPanelModule,
      providers: [
        {
          provide: PARTICIPATION_PANEL_ENGINE,
          useClass: engine,
        },
      ],
    };
  }
}
