@for (item of transformText(); track item; let isLast = $last) {
  <span [innerHTML]="item"> </span>
  @if (!isLast) {
    <mat-icon
      class="currency-prefix"
      [class]="iconClass()"
      [svgIcon]="icon()"
    ></mat-icon>
  }
}
