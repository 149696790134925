import { InjectionToken } from '@angular/core';
import { NavigationExtras, Params } from '@angular/router';
import {
  BreakpointsTypes,
  IGameSettings,
  IInventoryItem,
  IInventoryRequestParams,
  ModalNames,
  StateActionStatus,
  TradeOriginalItem,
} from '@dev-fast/types';
import { Observable } from 'rxjs';

export const PARTICIPATION_PANEL_ENGINE = new InjectionToken<ParticipationPanelEngine>('PARTICIPATION_PANEL_ENGINE');

export interface ParticipationPanelEngine {
  // USER STATE
  readonly isAuth$: Observable<boolean>;
  // INVENTORY STATE
  readonly inventoryParams$: Observable<IInventoryRequestParams>;
  readonly maxInventoryPages$: Observable<number | null>;
  readonly inventoryItems$: Observable<IInventoryItem[] | null>;
  readonly selectedItems$: Observable<IInventoryItem[]>;
  readonly newItemsSum$: Observable<number>;
  readonly newItemsCount$: Observable<number>;
  readonly participatedItems$: Observable<TradeOriginalItem[] | null>;
  readonly participatedItemsFromGame$: Observable<TradeOriginalItem[] | null>;
  readonly participatedItemsSum$: Observable<number>;
  readonly inventoryLoadStatus$: Observable<StateActionStatus>;
  readonly addBetStatus$: Observable<StateActionStatus>;
  readonly timer$: Observable<number>;
  readonly temporaryItems$: Observable<IInventoryItem[]>;
  // GAMES STATE
  readonly settings$: Observable<IGameSettings | null>;
  // LAYOUT STATE
  readonly breakpoints$: Observable<BreakpointsTypes | null>;
  readonly isPanelOpened$: Observable<boolean>;

  onItemClick(item: IInventoryItem): void;
  getInventoryItems(params?: IInventoryRequestParams): void;
  unselectItems(): void;
  placeBet(items: IInventoryItem[]): void;
  navigateTo(path: string, queryParams?: Params | undefined, extras?: NavigationExtras | undefined): void;
  openModal(modal: ModalNames, params?: any): void;
  togglePanel(): void;
}
